export const TRANSLATIONS_FR = {"statisticsIcon":"Statistiques",
"colon":" :",
"menuIcon":"Menu",
"configurationMenu":"Configuration",
"appTitle":"95% English Words",
"appTitleLong":"95% des mots anglais - Apprendre le vocabulaire",
"copyrightMenu":"Remerciements",
"copyrightWordreference":"WordReference est un exceptionnel, riche et très précieux dictionnaire collaboratif. Merci beaucoup à son fondateur Mike Kellogg de nous avoir généreusement accordé les droits d’usage à travers notre application.",
"copyrightFrequency":"Les 5 000 mots ont été précieusement choisis à partir de différentes sources de données dont une principale est issue du site www.wordfrequency.info qui se base lui même sur le milliard de mots du corpus COCA (Corpus of Contemporary American English).",
"premiumWordAccess":"Mot premium. Tu as besoin de souscrire au mode premium pour pratiquer ce mot",
"reset_toolTip":"Très difficile d'identifier le sens de ce mot.\nBesoin de consulter le dictionnaire.\nAppuyer sur ce bouton pour redémarrer l'apprentissage de ce mot depuis le début",
"reset_button":"Redémarrer",
"notOk_toolTip":"Clique ici s’il est difficile de se souvenir des principaux sens de ce mot ou de sa prononciation. Avant de cliquer, révise le mot dans le dictionnaire !",
"notOk_button":"Non Ok",
"ok_toolTip":"Clique ici s’il est facile de se rappeler des principaux sens de ce mot et de sa prononciation.",
"ok_button":"Ok",
"almostMastered_toolTip":"Cliquer ici si tu maitrises déjà parfaitement le sens et prononciation de ce mot.\nAttention, ce mot ne te sera plus jamais proposé par la suite !",
"almostMastered_button":"Presque maitrisé",
"nomorerevision_toolTip":"Clique ici si tu ne souhaites plus voir ce mot dans le mode révision.",
"nomorerevision_button":"Arrêter la révision sur ce mot",
"mastered_toolTip":"Clique ici si tu maitrises déjà parfaitement le sens et prononciation de ce mot.\nAttention, ce mot ne te sera alors plus jamais proposé par la suite.",
"mastered_button":"Maitrisé!",
"bottomNavFirst_toolTip":"Premier",
"bottomNavPrevious_toolTip":"Précédent",
"bottomNavNext_toolTip":"Suivant",
"bottomNavLast_toolTip":"Dernier",
"bottomNavCurrent_toolTip":"Position de ce mot dans ton flux d'apprentissage courant\nCliquer sur le bouton 'Premier' (sur la gauche) pour aller au début de ton flux d'apprentissage",
"bottomNavCurrent0_toolTip":"Position de ce mot dans ton flux d'apprentissage courant",
"lemmapos_toolTip":"Le mot à mémoriser\nLa forme du mot à mémoriser (verbe, nom, adjectif, adverbe, interjection, ...)",
"inflections_toolTip":"Differentes conjugaisons du mot (avec pluriels sur les noms ou adjectifs, ou verbes conjugués)",
"inflections_label":"Inflections",
"rank_toolTip":"Rang de ce mot correspondant à sa fréquence d'usage",
"rank_toolTipSearch":"Rang de ce mot",
"rank_label":"Rang",
"englishLevelWordTitle_toolTip":"Niveau d'anglais de ce mot",
"englishLevelWordA_toolTip":"A1, A2 : débutant, élémentaire",
"englishLevelWordB_toolTip":"B1, B2 : intermédiaire, intermédiaire supérieur",
"englishLevelWordC_toolTip":"C1, C2 : avancé, courant",
"englishLevelWord_label":"Niveau d'anglais",
"englishLevelWord_toolTipSearch":"Niveau d'anglais de ce mot",
"maturityTitle_toolTip":"Signification de la maturité :",
"maturity0_toolTip":"0 : pas encore pratiqué",
"maturity1_toolTip":"1-9 : en cours d'apprentissage",
"maturity2_toolTip":"10-14 : presque maitrisé",
"maturity3_toolTip":">14 : maitrisé",
"maturity_label":"Ta maturité pour ce mot",
"lastPracticed_label":"Dernière pratique",
"lastPracticedNone_label":"aucune",
"nextPractice_toolTip":"Prochaine pratique : date planifiée de la prochaine pratique de ce mot (quand le facteur de delai atteindra 1.00. Plus la maturité est basse plus la date sera proche)\nFacteur de delai courant: facteur de délai entre la date de dernière pratique et la prochaine date planifiée de pratique",
"nextPractice_label":"Prochaine pratique",
"currentFactorDelay_label":"Facteur de delai actuel",
"nextPracticeIfOk_label":"Prochaine pratique si 'Ok'",
"nextPracticeIfKo_label":"Prochaine pratique si 'Non Ok'",
"showWordReference_label":"Montrer ce mot sur le site WordReference",
"configure_toolTip":"Configurer l'application",
"configure_title":"Configurer",
"tutorialMenu":"Tutorial",
"premiumMenu":"Premium",
"configureWordReferenceLanguageTranslation_title":"Langage de traduction sur WordReference",
"configureLeftHandedView_title":"Mode gaucher",
"configureExpertModeView_title":"Mode expert",
"configureToolTips_title":"Aide contextuelle",
"configureSetMastered_toolTip":"Mettre automatiquement à ‘Maitrisé’ tous les mots jusqu’au niveau d’anglais suivant inclus (sélectionner)",
"configureSetMastered_title":"Mettre tous les mots à ‘Maitrisé’",
"configureThemeChoice_toolTip":"Choisir le thème (COCA : valeur initiale correspondant à la base de 95% des mots anglais, PhrasalVerbs : mode premium uniquement, 150 verbes à particule)",
"set_Label":"Go !",
"configureSetMastered_confirm":"Attention, tu vas mettre tous les mots à ‘Maitrisé’ jusqu’au niveau d’anglais ENGLISHLEVEL ! Confirmes-tu cette opération (non réversible) ?",
"revisionModeActivate_toolTip":"Clique pour activer le mode révision",
"revisionModeDeactivate_toolTip":"Clique pour désactiver le mode révision",
"revisionMode_toolTip":"Mode révision: révise les mots que tu as le plus de mal à mémoriser",
"searchPage_toolTip":"Aller à un mot",
"searchPage_Title":"Chercher des mots",
"searchPageFilter_toolTip":"filtrer",
"searchPageCloseFilter_toolTip":"fermer le filtre",
"searchPageSortHistorical_label":"Historique de pratique",
"searchPageSortAlphabetical_label":"Alphabétique",
"searchPageSortPracticeLevel_label":"niveau de pratique",
"searchPageSortEnglishLevel_label":"Niveau d'anglais",
"searchPageSortRank_label":"Rang",
"maturityLevel_label":"Niveau de maturité",
"search_label":"Chercher",
"sortOrder_label":"Ordre de tri",
"word_label":"Mot",
"position_label":"Position",
"englishLevel_label":"Niveau d'anglais",
"practiceMaturity_label":"Niveau de pratique",
"lastTimePracticed_label":"Dernière fois pratiqué",
"noResultFound_label":"Pas de résultats trouvés",
"sortValue_label":"Valeur de tri",
"statistics_label":"Statistiques",
"statisticsHistoricTotal_toolTips":"Nombre de mots pratiqués sur le jour (si un même mot est pratiqué plusieurs fois, il est compté d’autant)",
"statisticsHistoricUnique_toolTips":"Nombre de mots distincts pratiqués sur le jour (si un même mot est pratiqué plusieurs fois, il est compté une seule fois)",
"learningStatus_label":"Status de ton apprentissage",
"currentEnglishLevel_label":"Ton niveau d'anglais actuel",
"wordsToMasterToNextLevel_label":"Nombre de mots à maitriser pour atteindre le niveau supérieur",
"totalDistinctWords_label":"Nombre total de mots disponibles",
"wordsCountPerDay_label":"Nombre de mots pratiqués par jour",
"day_label":"Jour",
"total_label":"Total",
"unique_label":"Uniques",
"new_label":"Nouveaux",
"newAlmostMastered_label":"Nouveaux presque maitrisés",
"newMastered_label":"Nouveaux maitrisés",
"configureAppLanguage_title":"Langue de l'application",
"confirmation_label":"Confirmation",
"confirmationDialogBox_text":"Attention, tu n'es pas au début de ton flux d'apprentissage (mais à la page PAGENUMBER). Tu dois confirmer ton action",
"yes_label":"Oui",
"no_label":"Non",
"welcome_head":"Bienvenue dans 95% des mots anglais",
"welcome_head2":"Apprends le plus efficacement possible le vocabulaire anglais avec cette application",
"welcome_bullet1":"Apprends et maitrise les 5000 mots qui représentent 95% des mots anglais utilisés à l'oral et atteints le niveau C1 (expérimenté). Apprentissage progressif basé sur la fréquence d'usage des mots à l’oral.",
"welcome_bullet2":"Mémorise de façon optimale grâce à la répétition espacée.",
"welcome_sentence":"Le tutorial qui suit décrit toutes les fonctionnalités et astuces pour prendre en main cette application le plus rapidement et efficacement possible.\nMais avant de rentrer dans le détail, quelques mots sur les niveaux d’anglais auxquels l’application fait référence, ainsi que sur comment ont été choisis les 5 000 mots de vocabulaire.",
"welcomeEnglishLevel_head":"Niveaux d'anglais",
"welcomeEnglishLevel_introduction":"Le CECRL (Cadre Européen Commun de Référence pour les Langues) ou encore CEFR en anglais (Common European Framework of Reference) a défini l'échelle de compétences linguistiques suivante:",
"welcomeEnglishLevel_A0Label":"A0: grand débutant",
"welcomeEnglishLevel_A1Label":"A1: débutant (1000 premiers mots les plus fréquents)",
"welcomeEnglishLevel_A2Label":"A2: élémentaire (1600 premiers mots les plus fréquents)",
"welcomeEnglishLevel_B1Label":"B1: intermédiaire (2500 premiers mots les plus fréquents)",
"welcomeEnglishLevel_B2Label":"B2: intermédiaire supérieur (3700 premiers mots les plus fréquents)",
"welcomeEnglishLevel_C1Label":"C1: avancé (5000 premiers mots les plus fréquents)",
"welcomeEnglishLevel_C2Label":"C2: courant/bilingue (10000 premiers mots les plus fréquents)",
"welcomeFrequnencyList_head":"5 000 mots",
"welcomeFrequnencyList_body":"Ces 5 000 mots ont été choisis en fonction de leur fréquence d'usage. Ils ont été identifiés à partir d'un corpus d'un milliard de mots provenant de plusieurs centaines de milliers de textes issus de différents genres (films, séries télé, blogs, ...) de 1990 à 2019.\nCe sont les 5 000 mots les plus fréquemment utilisés à l’oral et ils incluent l'argot et les mots familiers.",
"welcomeInterfaceHome_head":"Interface principale",
"premium_title":"Premium",
"configureTheme_title":"Theme",
"welcomeInterfaceHome_intro":"Tu trouveras ici toutes les informations sur l'interface principale de l'application, section par section.",
"welcomeInterfaceHome_WordPosition":"Le mot à apprendre (ici 'smug') sous la forme donnée (ici 'adjectif').",
"welcomeInterfaceHome_inflections":"Inflections : les différentes formes déclinées du mot. A apprendre également.",
"welcomeInterfaceHome_rank":"Rang d'un mot : parmi les 5 000 mots, celui-ci a le rang 3438. Cela signifie qu'il est 3438 ème dans les mots les plus fréquemment utilisés à l'oral.",
"welcomeInterfaceHome_englishLevel":"Niveau d'anglais d'un mot : en relation avec son rang, ce mot a un niveau d'anglais correspondant à B2 (intermédiaire supérieur).",
"welcomeInterfaceHome_maturity":"Ta maturité actuelle sur ce mot.",
"welcomeInterfaceHome_maturity2":"La maturité augmentera lorsque tu répondras ‘OK’, diminuera lorsque tu répondras ‘Non OK’.",
"welcomeInterfaceHome_maturity3":"La fréquence de pratique d'un mot dépend de ta maturité sur ce mot. Plus la maturité est élevée, plus la fréquence est faible.",
"welcomeInterfaceHome_maturity4":"Par exemple, pour une maturité de 2 la fréquence de pratique sera toutes les 2 minutes (5 : 16 minutes - 10 : 8 heures - 15 : 11 jours).",
"welcomeInterfaceHome_wordreference":"Clique sur ce lien pour accéder au dictionnaire WordReference sur ce mot et apprendre ou vérifier sa signification et sa prononciation.\nTu peux configurer la langue de traduction de WordReference dans le menu 'Configuration'.",
"welcomeInterfaceHome_sidenavTitle":"Voici la barre de navigation principale :",
"welcomeInterfaceHome_bottomnavTitle":"Cette barre de navigation additionnelle te permet de naviguer dans ton flux de pratiques.",
"welcomeInterfaceHome_bottomnavButtons1":"Le flux de pratique est la séquence de mots à pratiquer à un moment donné. Le flux est trié en fonction du délai de pratique du mot (c'est-à-dire en fonction de ta maturité et de la date de la dernière pratique).",
"welcomeInterfaceHome_bottomnavButtons2":"Utilise les boutons pour naviguer. Le nombre au centre indique le numéro de séquence actuel du mot. Tu es censé pratiquer les mots au début du flux (numéro de séquence = 1).",
"welcomeInterfaceHomeExpert_head":"Mode expert",
"welcomeInterfaceHomeExpert_intro":"Lorsque le mode expert est activé, certaines fonctionnalités supplémentaires sont disponibles :\n• Dans l'interface d'accueil : voir ci-dessous,\n• Recherche de mots : voir page suivante.",
"welcomeInterfaceHomeExpert_intro2":"Ci-dessus, l’interface principale lorsque le mode expert est activé.",
"welcomeInterfaceHomeExpert_maturity":"De nouvelles informations supplémentaires sont disponibles en mode expert dans la zone de maturité:",
"welcomeWordReference_head":"Interface WordReference",
"welcomeWordReference_intro":"Utilise le dictionnaire pour réviser un mot ou pour l'apprendre.\nIl est important d'apprendre la prononciation (clique sur le bouton ‘Listen’ et répète le à haute voix jusqu’à ce que la prononciation soit parfaitement identique), les différentes significations du mot pour sa position (ici 'smug' comme 'adjectif' ) et de voir son utilisation en situation (à travers des exemples donnés).",
"welcomeConfiguration_head":"Configuration",
"welcomeConfiguration_intro":"Cette page te permet de configurer l'application :",
"configurationLanguage_toolTips":"Langue de l'application : choisis la langue de l’interface de l’application",
"configurationWR_toolTips":"Langue de traduction WordReference : choisis ta langue de traduction préférée pour le dictionnaire",
"configurationLeft_toolTips":"Vue gaucher : sélectionne cette option si tu préfères avoir les boutons de pratique sur la gauche de l'écran",
"configurationExpert_toolTips":"Affichage en mode expert : sélectionne cette option si tu souhaites accéder à des fonctionnalités avancées (rechercher un mot, naviguer dans ton flux d'entraînement actuel, avoir des informations supplémentaires sur l'écran d’accueil, accéder au mode révision)",
"configurationTips_toolTips":"Info-bulles : si activé, un appui long sur une zone d'un écran affichera des guides liés à la zone sélectionnée",
"configurationThemeChoice_toolTips":"Choix du thème (premium version seulement) : permet de sélectionner le thème de mots à pratiquer.\nValeurs possibles : COCA (valeur initiale correspondant à la base de 95% des mots anglais), PhrasalVerbs (version premium uniquement, 150 verbes à particule)",
"welcomeSearch_head":"Rechercher un mot",
"welcomeSearch_intro":"Cette page te permet de rechercher des mots. Tu peux taper un mot, filtrer sur des critères d'anglais ou de niveaux de maturité et trier le résultat sur différents critères,\nClique sur un mot pour l'afficher dans ton écran d’accueil.",
"welcomeStats_head":"Statistiques",
"welcomeStats_intro":"Cette page présente quelques statistiques liées à ta progression et à tes pratiques journalières.",
"welcomeTips_head":"Astuces",
"welcomeTips_intro1":"Navigation : Pour revenir en arrière (depuis le dictionnaire, la configuration, les statistiques, la recherche), balayer à droite depuis le bord gauche de l’écran.\nCi-dessus par exemple pour revenir à l’écran d’accueil depuis le dictionnaire.",
"welcomeTips_intro2":"Info-bulles : un appui long sur une section affichera des guides. Ci-dessus, les informations affichées après un appui long sur la zone Maturité.",
"welcomePremium_head":"Version Premium",
"welcomePremium_intro":"La version Premium offre le contenu additionnel suivant",
"welcomePremium_bullet1":"Accès à la totalité des 5 000 mots (5 050 en réalité) couvrant 95% des mots anglais utilisés à l’oral",
"welcomePremium_bullet2":"Accès au nouveau thème PhrasalVerbs contenant les 150 verbes à particule anglais les plus utilisés à l’oral (60% du total). Les niveaux d’anglais sont spécifiques sur ce thème (les 150 verbes correspondent au niveau B2)",
"welcomeLast_head":"Au début…",
"welcomeLast_sentence":"Au début, selon ton niveau d'anglais, tu connaîtras déjà un plus ou moins grand nombre de mots. Clique alors sur ‘Maîtrisé’ pour chaque mot que tu maîtrises déjà.\nSois patient, au début tu devras peut-être faire cette opération sur plusieurs centaines de mots. C'est une étape nécessaire pour s'assurer que tu ne manques pas certains mots de base que tu ne maîtrises pas.\nUne fois cette première étape franchie, tu rencontreras de plus en plus régulièrement des mots que tu ne maîtrises pas encore.\nProfite !",
"welcomeLast_but":"Mais…",
"welcomeLast_setmastered":"Ok, si tu es un peu impatient et vraiment sûr de maitriser déjà un ou plusieurs niveaux, il est possible de mettre à ‘Maitrisé’ automatiquement tous les mots d’un ou plusieurs niveaux. Pour cela, va dans le menu configuration et la ligne ci-dessus va s’afficher. Choisis le niveau jusqu’où mettre tous les mots à ‘Maitrisé’ et appuie sur le bouton.",
"welcomeLast_sentence2":"Un dernier conseil:\nN’hésite surtout pas à sélectionner ‘Non Ok’ dès que tu n’es pas complètement à l’aise avec un mot (sur son sens ou prononciation), même si tu as atteint un haut niveau de maturité, bien au contraire !\nIl est tout à fait normal (et fortement conseillé) d’avoir régulièrement des mots à re-pratiquer plus régulièrement, et cela peut arriver plusieurs fois sur le même mot.\nAprès un certains de temps de pratique, activer de temps en temps le mode révision (image ci-dessous) pour revoir tous les mots que tu as eu le plus de mal à pratiquer.",
"welcomeLast_sentence3":"Lorsque le mode révision est activé, l’interface évolue légèrement:",
"welcomeLast_sentence3_bullet1":"tu peux toujours utiliser les boutons ‘Non Ok’ et ‘Ok’ mais il n’auront une action significative sur la maturité que si le facteur de délai courant est supérieur à 1.00. Le mot ne te sera par contre plus proposé en mode révision pendant 1 heure,",
"welcomeLast_sentence3_bullet2":"Le bouton ‘maitrisé!’ est remplacé par le bouton ‘Arrêter la révision sur ce mot’. Un click sur ce bouton remettra le compteur de révision à zéro pour ce mot. Il ne te sera alors plus proposé en révision sauf si par la suite tu as encore des difficultés à le retenir (‘Non Ok’ sélectionné plusieurs fois par la suite).",
"adminMenu":"Admin",
"premium_subscribe":"Acheter",
"premium_restore":"Restaurer les achats",
"premium_availableproducts":"Produits disponibles",
"premium_freemium":"Gratuit",
"premium_freemiumdescription":"Accès aux 2500 premiers mots (niveau B1)",
"premium_restoreNotFound":"Pas d’achat précédent trouvé",
"reviewapp_title":"Noter l’app",
"expertModePremiumOnly_title":"Mode expert accessible en version Premium",
"expertModePremiumOnly_dialog":"Pour accéder au mode expert, souscrivez à la version Premium",
"themeChoicePremiumOnly_title":"Choix du thème possible seulement en version Premium",
"themeChoicePremiumOnly_dialog":"Pour pouvoir changer de thème, souscrivez à la version Premium",
"countWordsToPractice_title":"Nb de mots avec ‘facteur de délai’ courant > 1",
"underConstruction":"En construction…",
"privacyPolicy_label":"Politique de confidentialité",
"inshort_label":"En bref",
"inshort_sentence":"L'application '95% English Words' ne collecte ni ne partage aucune de vos données personnelles.\n\nNous ne vous suivons pas, nous ne vous profilons pas. C'est tout !",
"presentation_title":"Présentation de l’application",
"presentation_appAppStore":"Cette application est disponible sur le store apple pour iPhone ou iPad",
"presentation_appAndroidStore":"Télécharger dans Google Play Store",
"appLink":"https://apps.apple.com/fr/app/95-english-words/id1590141828",
"appLinkAndroid":"https://play.google.com/store/apps/details?id=com.lezoomer.englishwords",
"appleApp_ID":"1590141828",
"appDownload_title":"Télécharger dans l’App Store",
"appKeywords":"anglais, vocabulaire, parlé, langue, argot, fréquence, bilingue, apprendre, liste de fréquences, vocabulaire anglais, répétition espacée, flashcards",
"appDescription":"Accessible à tous, quelques minutes par jour suffisent sur notre application mobile.  Apprends les 5000 mots anglais qui représentent 95% du vocabulaire parlé.",
"app_h1_1":"La méthode efficace et pertinente pour apprendre les mots courants anglais !",
"app_h2_1":"Apprends avec cette application le vocabulaire anglais équivalent au niveau international C1 expérimenté",
"app_h3_1":"Un vocabulaire pertinent",
"app_h3_1_description":"Maîtrise les 5000 mots qui représentent 95% des mots anglais utilisés à l'oral. L'apprentissage est progressif et basé sur la fréquence d'usage des mots à l’oral.",
"app_h3_2":"Une méthode efficace",
"app_h3_2_description":"Mémorise de façon optimale grâce à la répétition espacée, la technique pour tout retenir au moindre effort.",
"app_h3_3":"Un dictionnaire de référence mondiale",
"app_h3_3_description":"Apprends les mots, leurs prononciations, leurs définitions et exemples d'usage avec l'appui de l'excellent et fameux dictionnaire collaboratif WordReference.",
"app_h3_4":"Pratique quand tu veux, où tu veux",
"app_h3_4_description":"5mn de pause ? Profites en pour pratiquer avec notre application iPhone et iPad (et prochainement Android).\nLes 2 500 premiers mots sont gratuits avec accès à toutes les fonctionnalités de l'application.\nLa version payante permet d'accéder aux 5 000 mots ainsi qu'aux 150 mots à particules les plus fréquents.",
"app_h2_2":"Une interface simple, riche et efficace",
"app_h2_3":"Télécharge notre application pour iPhone ou iPad",
"wordfrequency_link":"https://www.wordfrequency.info",
"wordreference_link":"https://www.wordreference.com",
"spacedrepetition_link":"https://fr.wikipedia.org/wiki/R%C3%A9p%C3%A9tition_espac%C3%A9e",
"spacedrepetition_imgpath":"/img/repetitionsespacees.png"};

export const TRANSLATIONS_EN = {"statisticsIcon":"Statistics",
"colon":":",
"menuIcon":"Menu",
"configurationMenu":"Configuration",
"appTitle":"95% English Words",
"appTitleLong":"95% of English Words - The best way to learn english vocabulary",
"copyrightMenu":"Thanks",
"copyrightWordreference":"WordReference is an exceptional, rich and very valuable collaborative dictionary. Many thanks to its founder Mike Kellogg for generously granting us the rights to use our app.",
"copyrightFrequency":"The 5,000 words have been carefully chosen from different data sources, one of which comes from the site www.wordfrequency.info which itself is based on the billion words of the COCA (Corpus of Contemporary American English) corpus.",
"premiumWordAccess":"Premium word. You need premium access to practice this word",
"reset_toolTip":"Very hard for me to remember the meaning.\nNeed to see it in dictionary.\nPress this button to set maturity to 1 and restart practice for that word from the beginning",
"reset_button":"Restart",
"notOk_toolTip":"Tap here if it's not easy to remember the main meanings or the pronunciation of this word. Before taping, review it in the dictionary!",
"notOk_button":"Not Ok",
"ok_toolTip":"Tap here if it's easy to remember the main meanings and pronunciation of this word",
"ok_button":"Ok",
"almostMastered_toolTip":"Tap here if you already master the meanings and pronunciation of this word\nBe careful, this word will never proposed afterwards!",
"almostMastered_button":"Almost Mastered",
"nomorerevision_toolTip":"Click here if you no longer want to see this word in review mode.",
"nomorerevision_button":"Stop revision on this word",
"mastered_toolTip":"Tap here if you already master the meanings and pronunciation of this word\nBe careful, this word will never proposed again afterwards.",
"mastered_button":"Mastered!",
"bottomNavFirst_toolTip":"First",
"bottomNavPrevious_toolTip":"Previous",
"bottomNavNext_toolTip":"Next",
"bottomNavLast_toolTip":"Last",
"bottomNavCurrent_toolTip":"Current position of this word in your learning practice stream\nClick on 'First' arrow (on left) to go to the start of the stream",
"bottomNavCurrent0_toolTip":"Current position of this word in your learning practice stream",
"lemmapos_toolTip":"The word to memorize\nThe position of the word to memorize (verb, noun, adjective, adverb, interjection, ...)",
"inflections_toolTip":"Various conjugations of the word (with plural or feminine mark on nouns or adjectives, or conjugated verbs)",
"inflections_label":"Inflections",
"rank_toolTip":"Rank of this word in its frequency of use",
"rank_toolTipSearch":"Rank of this word",
"rank_label":"Rank",
"englishLevelWordTitle_toolTip":"English level of this word",
"englishLevelWordA_toolTip":"A1, A2: beginner, elementary",
"englishLevelWordB_toolTip":"B1, B2: intermediate, uppper-intermediate",
"englishLevelWordC_toolTip":"C1, C2: advanced, proficiency",
"englishLevelWord_label":"English level",
"englishLevelWord_toolTipSearch":"English level of this word",
"maturityTitle_toolTip":"Maturity meaning:",
"maturity0_toolTip":"0: not seen yet",
"maturity1_toolTip":"1-9: learning",
"maturity2_toolTip":"10-14: almost mastered",
"maturity3_toolTip":">14: mastered",
"maturity_label":"Your maturity for this word",
"lastPracticed_label":"Last practiced",
"lastPracticedNone_label":"none",
"nextPractice_toolTip":"• Date of your last practice for this word, \n• Date of the next planned practice: when current factor delay will reach 1.00,\n• Current factor delay: delay factor between the last time practiced and the next planned practice, related to maturity. When the next planned practice date has passed, the delay factor will be greater than 1,\n• Next practice if 'Ok' (or 'Not Ok'): next practice planned date if you answer 'Ok' or ('Not Ok')",
"nextPractice_label":"Next practice",
"currentFactorDelay_label":"current factor delay",
"nextPracticeIfOk_label":"Next practice if 'Ok'",
"nextPracticeIfKo_label":"Next practice if 'Not Ok'",
"showWordReference_label":"Show this word on WordReference site",
"configure_toolTip":"Configure the application",
"configure_title":"Configure",
"tutorialMenu":"Tutorial",
"premiumMenu":"Premium",
"configureWordReferenceLanguageTranslation_title":"WordReference translation language",
"configureLeftHandedView_title":"Left Handed View",
"configureExpertModeView_title":"Expert Mode",
"configureToolTips_title":"Tool Tips",
"configureSetMastered_toolTip":"Automatically set to 'Mastered' all words up to and including the following level of English (select)",
"configureSetMastered_title":"Set all words to ‘Mastered’",
"configureThemeChoice_toolTip":"Choose the theme (COCA: initial value corresponding to the base of 95% of English words, PhrasalVerbs: premium mode only, 150 phrasal verbs)",
"set_Label":"Go!",
"configureSetMastered_confirm":"Be careful, you will set all the words to ‘Mastered' up to the ENGLISHLEVEL level of English! Do you confirm this operation (not reversible)?",
"revisionModeActivate_toolTip":"Click to turn on the revision mode",
"revisionModeDeactivate_toolTip":"Click to turn off the revision mode",
"revisionMode_toolTip":"Revision mode: review the words you have the most difficulties to memorize",
"searchPage_toolTip":"Go to a specific word",
"searchPage_Title":"Search words",
"searchPageFilter_toolTip":"filter",
"searchPageCloseFilter_toolTip":"close filter",
"searchPageSortHistorical_label":"Historical practice",
"searchPageSortAlphabetical_label":"Alphabetical",
"searchPageSortPracticeLevel_label":"level of practice",
"searchPageSortEnglishLevel_label":"English level",
"searchPageSortRank_label":"Rank",
"maturityLevel_label":"Maturity level",
"search_label":"Search",
"sortOrder_label":"Sort order",
"word_label":"Word",
"position_label":"Position",
"englishLevel_label":"English level",
"practiceMaturity_label":"Maturity of practice",
"lastTimePracticed_label":"Last time practiced",
"noResultFound_label":"No result found",
"sortValue_label":"Sort value",
"statistics_label":"Statistics",
"statisticsHistoricTotal_toolTips":"Number of words practiced on the day (if the same word is practiced several times, it is counted as many)",
"statisticsHistoricUnique_toolTips":"Number of distinct words practiced on the day (if the same word is practiced several times, it is counted only once)",
"learningStatus_label":"Learning status",
"currentEnglishLevel_label":"Your current english level",
"wordsToMasterToNextLevel_label":"Words to master to get next level",
"totalDistinctWords_label":"Total available words count",
"wordsCountPerDay_label":"Words count practiced per day",
"day_label":"Day",
"total_label":"Total",
"unique_label":"Unique",
"new_label":"New",
"newAlmostMastered_label":"New almost mastered",
"newMastered_label":"New mastered",
"configureAppLanguage_title":"Application language",
"confirmation_label":"Confirmation",
"confirmationDialogBox_text":"Beware, you're not at the beginning of your practice flow (but at page PAGENUMBER). Do you want to confirm action anyway?",
"yes_label":"Yes",
"no_label":"No",
"welcome_head":"Welcome in 95% of English Words",
"welcome_head2":"Learn English vocabulary as efficiently as possible with this application",
"welcome_bullet1":"Learn and master the 5,000 words which represent 95% of English words used orally and reach level C1 (experienced). Progressive learning based on the frequency of oral word use.",
"welcome_bullet2":"Memorize optimally thanks to spaced repetition.",
"welcome_sentence":"The following tutorial describes all the features and tips to get started with this application as quickly and efficiently as possible.\nBut before going into detail, a few words on the levels of English to which the application refers, as well as on how the 5,000 vocabulary words were chosen.",
"welcomeEnglishLevel_head":"English levels",
"welcomeEnglishLevel_introduction":"The CEFR (Common European Framework of Reference) has defined the following language skills scale:",
"welcomeEnglishLevel_A0Label":"A0: great beginner",
"welcomeEnglishLevel_A1Label":"A1: beginner (most frequent first 1000 words)",
"welcomeEnglishLevel_A2Label":"A2: elementary (1,600 most frequent first words)",
"welcomeEnglishLevel_B1Label":"B1: intermediate (2500 most frequent first words)",
"welcomeEnglishLevel_B2Label":"B2: upper intermediate (3700 most frequent first words)",
"welcomeEnglishLevel_C1Label":"C1: advanced (5000 first most frequent words)",
"welcomeEnglishLevel_C2Label":"C2: fluent / bilingual (10,000 most frequent first words)",
"welcomeFrequnencyList_head":"5,000 words",
"welcomeFrequnencyList_body":"These 5,000 words were chosen based on their frequency of use. They were identified from a corpus of one billion words from several hundred thousand texts from different genres (movies, TV series, blogs, ...) from 1990 to 2019.\nThese are the 5 000 most frequently used spoken words and they include slang and colloquial words.",
"welcomeInterfaceHome_head":"Home interface",
"premium_title":"Premium",
"configureTheme_title":"Theme",
"welcomeInterfaceHome_intro":"Here you will find all the information about the main interface of the application, section by section.",
"welcomeInterfaceHome_WordPosition":"The word to learn (here 'smug') in the given form (here 'adjective').",
"welcomeInterfaceHome_inflections":"Inflections: the different declined forms of the word. Also to learn.",
"welcomeInterfaceHome_rank":"Rank of a word: among the 5,000 words, this one has the rank of 3438. This means that it is 3438 th in the words most frequently used orally.",
"welcomeInterfaceHome_englishLevel":"English level of a word: in relation to its rank, this word has an English level corresponding to B2 (upper intermediate).",
"welcomeInterfaceHome_maturity":"Your current maturity on this word.",
"welcomeInterfaceHome_maturity2":"The maturity will increase when you answer ‘OK’, will decrease when you answer ‘Not Ok’.",
"welcomeInterfaceHome_maturity3":"The frequency of practice of a word depends on your maturity on that word. The higher the maturity, the lower the frequency.",
"welcomeInterfaceHome_maturity4":"For example, for a maturity of 2 the frequency of practice will be every 2 minutes (5: 16 minutes - 10: 8 hours - 15: 11 days).",
"welcomeInterfaceHome_wordreference":"Click on this link to access to the WordReference dictionary on that word and learn or check its meaning and pronunciation.\nYou can configure the translation language of WordReference in the 'Configuration' menu.",
"welcomeInterfaceHome_sidenavTitle":"This is the main navigation bar:",
"welcomeInterfaceHome_bottomnavTitle":"This additional bottom navigation bar allow you to navigate through your flow of practices.",
"welcomeInterfaceHome_bottomnavButtons1":"The flow of practice is the sequence of words to practice at a given moment. The flow is sorted based on the delay of practicing of word (that is based on your maturity on it and the date of the last practice).",
"welcomeInterfaceHome_bottomnavButtons2":"Use the buttons to navigate. The number in the center indicates the current sequence number for the word. You're supposed to practice the words at the beginning (sequence number = 1).",
"welcomeInterfaceHomeExpert_head":"Expert mode",
"welcomeInterfaceHomeExpert_intro":"When expert mode is enabled, some additional features are available:\n• In the home interface: see below,\n• Search words: see next page.",
"welcomeInterfaceHomeExpert_intro2":"Above, the main interface when expert mode is activated.",
"welcomeInterfaceHomeExpert_maturity":"New addtional informations are available in expert mode in the maturity area:",
"welcomeWordReference_head":"WordReference interface",
"welcomeWordReference_intro":"Use the dictionary to review a word or to learn it.\nIt's important to learn the pronunciation (click on 'Listen' button and repeat it out loud until it is exactly the same pronunciation), the different meanings of the word for its position (here 'smug' as an 'adjective') and to see its use in situation (through examples given).",
"welcomeConfiguration_head":"Configuration",
"welcomeConfiguration_intro":"This page allows you to configure the application:",
"configurationLanguage_toolTips":"Application language: choose the language of the application interface",
"configurationWR_toolTips":"WordReference translation language: choose your prefered translation langage for your dictionary",
"configurationLeft_toolTips":"Left Handed View: select this option if you prefered to have the practice buttons on the left of the screen",
"configurationExpert_toolTips":"Expert Mode View: select this option if you want to access advanced features (search a word, navigate through your current practice flow, have additional informations on home screen, get access to the revision mode)",
"configurationTips_toolTips":"Tool Tips: if enabled, a long press on a area on some screen will display some guides related to the area selected",
"configurationThemeChoice_toolTips":"Choice of theme (premium version only): allows you to select the theme of words to practice.\nPossible values: COCA (initial value corresponding to the base of 95% of English words), PhrasalVerbs (premium version only, 150 phrasal verbs)",
"welcomeSearch_head":"Search a word",
"welcomeSearch_intro":"This page allows you to search words. You can type a word, filter on english or maturity levels criterias and sort the result on different criterias,\nClick on a word to display it in your home screen.",
"welcomeStats_head":"Statistics",
"welcomeStats_intro":"This page shows some statistics related to your progression and your daily practices.",
"welcomeTips_head":"Tips",
"welcomeTips_intro1":"Navigation: To go back (from dictionary, configuration, statistics, search), wipe right from left edge of the screen.\nAbove for example to return to the home screen from the dictionary.",
"welcomeTips_intro2":"ToolTips: long press on a section will show some guides. Above, the informations displayed after a long press on the ‘Maturity’ area.",
"welcomePremium_head":"Premium version",
"welcomePremium_intro":"The Premium version offers the following additional content",
"welcomePremium_bullet1":"Access to all 5,000 words (5,050 actually) covering 95% of English words used orally",
"welcomePremium_bullet2":"Access to the new PhrasalVerbs theme containing the 150 most commonly used English phrasal verbs (60% of the total). English levels are specific on this topic (the 150 verbs correspond to level B2)",
"welcomeLast_head":"At the beginning…",
"welcomeLast_sentence":"At the beginning, depending on your level of English, you will already know a greater or lesser number of words. Then tap on ‘Mastered’ for each word you already master.\nBe patient, at the beginning you may need to do this operation for several hundred words. This is a necessary step to make sure that you don't miss some basic words that you will not master. Once this first step is over, you will more and more regularly encounter words that you do not yet master.\nEnjoy!",
"welcomeLast_but":"But…",
"welcomeLast_setmastered":"Ok, if you are a little impatient and really sure to already master one or more levels, it is possible to automatically set to ‘Mastered’ all words on one or more levels. To do this, go to the configuration menu and the line bellow will be displayed. Choose the level up to which to put all the words to set to ‘Mastered’ and press the button.",
"welcomeLast_sentence2":"One last advice:\nDo not hesitate to select ‘Not Ok' as soon as you are not completely comfortable with a word (on its meaning or pronunciation), even if you have reached a high level of maturity, on the contrary!\nIt is quite normal (and strongly recommended) to have regular words to re-practice more regularly, and this can happen several times on the same word.\nAfter a while of practice, activate from time to time the revision mode (image below) to review all the words that you had the most difficulty practicing.",
"welcomeLast_sentence3":"When the revision mode is enabled, the interface evolve slightly:",
"welcomeLast_sentence3_bullet1":"you can still use the ‘Not Ok' and 'Ok' buttons but they will only have a significant effect on maturity if the current delay factor is greater than 1.00. On the other hand, the word will no longer be offered to you in review mode for 1 hour,",
"welcomeLast_sentence3_bullet2":"The ‘mastered!’ button is replaced by the ‘Stop revision on this word’ button. Clicking this button will reset the revision counter to zero for this word. It will then no longer be offered to you for review unless you still have difficulty retaining it later ('Not Ok' selected several times afterwards).",
"adminMenu":"Admin",
"premium_subscribe":"Buy",
"premium_restore":"Restore purchases",
"premium_availableproducts":"Available products",
"premium_freemium":"Free",
"premium_freemiumdescription":"Access to the first 2,500 words (level B1)",
"premium_restoreNotFound":"No previous purchase found",
"reviewapp_title":"Review app",
"expertModePremiumOnly_title":"Expert mode accessible in Premium version only",
"expertModePremiumOnly_dialog":"To access expert mode, subscribe to the Premium version",
"themeChoicePremiumOnly_title":"Choice of theme only possible in Premium version",
"themeChoicePremiumOnly_dialog":"To be able to change the theme, subscribe to the Premium version",
"countWordsToPractice_title":"No of words with current ‘factor delay’ > 1",
"underConstruction":"Under construction...",
"privacyPolicy_label":"Privacy policy",
"inshort_label":"In short",
"inshort_sentence":"95% English Words app does not collect or share your personal information.\n\nWe don't track you. We don't profile you. That's all!",
"presentation_title":"Presentation of the application",
"presentation_appAppStore":"This application is available on the apple store for iPhone or iPad",
"presentation_appAndroidStore":"Soon available on the Android store.",
"appLink":"https://apps.apple.com/us/app/95-english-words/id1590141828",
"appLinkAndroid":"https://play.google.com/store/apps/details?id=com.lezoomer.englishwords",
"appleApp_ID":"1590141828",
"appDownload_title":"Download in the App Store",
"appKeywords":"English, vocabulary, spoken, language, slang, frequency, bilingual, learn, frequency list, english vocabulary, spaced repetition, flashcards",
"appDescription":"Accessible to all, a few minutes a day are enough on our mobile application. Learn the 5000 English words that make up 95% of spoken vocabulary.",
"app_h1_1":"The effective and relevant method to learn fluent English words!",
"app_h2_1":"Learn with this application the English vocabulary equivalent to the international level C1 experienced",
"app_h3_1":"A relevant vocabulary",
"app_h3_1_description":"Master the 5000 words which represent 95% of the English words used orally. Learning is progressive and based on the frequency of oral word use.",
"app_h3_2":"An effective method",
"app_h3_2_description":"Memorize optimally thanks to spaced repetition, the technique to retain everything with the slightest effort.",
"app_h3_3":"A worldwide reference dictionary",
"app_h3_3_description":"Learn the words, their pronunciations, their definitions and examples of use with the support of the excellent and famous WordReference collaborative dictionary.",
"app_h3_4":"Practice when you want, where you want",
"app_h3_4_description":"5 minute break? Take the opportunity to practice with our iPhone and iPad application (and soon Android).\nThe first 2,500 words are free with access to all the application's features.\nThe paid version gives access to the 5,000 words as well as the 150 most frequent phrasal words.",
"app_h2_2":"A simple, rich and effective interface",
"app_h2_3":"Download our application for iPhone or iPad",
"wordfrequency_link":"https://www.wordfrequency.info",
"wordreference_link":"https://www.wordreference.com",
"spacedrepetition_link":"https://en.wikipedia.org/wiki/Spaced_repetition",
"spacedrepetition_imgpath":"/img/repetitionsespacees.png"};

export const TRANSLATIONS_ES = {"statisticsIcon":"Estadísticas",
"colon":" :",
"menuIcon":"Menú",
"configurationMenu":"Configuración",
"appTitle":"95% English Words",
"appTitleLong":"95% de las palabras en inglés - Aprende vocabulario",
"copyrightMenu":"Gracias",
"copyrightWordreference":"WordReference es un diccionario colaborativo excepcional, rico y muy valioso. Muchas gracias fundador Mike Kellogg por concedernos generosamente los derechos para utilizar nuestra aplicación.",
"copyrightFrequency":"Las 5.000 palabras han sido cuidadosamente seleccionadas de diferentes fuentes de datos, una de las cuales proviene del sitio www.wordfrequency.info, que a su vez se basa en los mil millones de palabras del corpus COCA (Corpus of Contemporary American English).",
"premiumWordAccess":"Palabra premium. Necesitas acceso premium para practicar esta palabra",
"reset_toolTip":"Es muy difícil para mí recordar el significado.\nNecesito verlo en el diccionario.\nPresione este botón para establecer la madurez en 1 y reiniciar la práctica para esa palabra desde el principio.",
"reset_button":"Reiniciar",
"notOk_toolTip":"Toque aquí si no es fácil recordar los significados principales o la pronunciación de esta palabra. Antes de grabar, revíselo en el diccionario.",
"notOk_button":"No Ok",
"ok_toolTip":"Toque aquí si es fácil recordar los significados principales y la pronunciación de esta palabra.",
"ok_button":"Ok",
"almostMastered_toolTip":"Toca aquí si ya dominas el significado y la pronunciación de esta palabra\n¡Ten cuidado, esta palabra nunca se propondrá después!",
"almostMastered_button":"Casi dominada",
"nomorerevision_toolTip":"Haga clic aquí si ya no desea ver esta palabra en el modo de revisión.",
"nomorerevision_button":"Detener la revisión de esta palabra",
"mastered_toolTip":"Toca aquí si ya dominas el significado y la pronunciación de esta palabra\nTen cuidado, esta palabra nunca se volverá a proponer después.",
"mastered_button":"¡Dominada!",
"bottomNavFirst_toolTip":"Primera",
"bottomNavPrevious_toolTip":"Previa",
"bottomNavNext_toolTip":"Próxima",
"bottomNavLast_toolTip":"Última",
"bottomNavCurrent_toolTip":"Posición actual de esta palabra en su flujo de práctica de aprendizaje\nHaga clic en la flecha 'Primera' (a la izquierda) para ir al inicio del flujo",
"bottomNavCurrent0_toolTip":"Posición actual de esta palabra en su flujo de práctica de aprendizaje",
"lemmapos_toolTip":"La palabra para memorizar\nLa posición de la palabra para memorizar (verbo, sustantivo, adjetivo, adverbio, interjección, ...)",
"inflections_toolTip":"Varias conjugaciones de la palabra (con marca plural o femenina en sustantivos o adjetivos, o verbos conjugados)",
"inflections_label":"Inflexiones",
"rank_toolTip":"Rango de esta palabra en su frecuencia de uso",
"rank_toolTipSearch":"Rango de esta palabra",
"rank_label":"Rango",
"englishLevelWordTitle_toolTip":"Nivel de inglés de esta palabra",
"englishLevelWordA_toolTip":"A1, A2: principiante, elemental",
"englishLevelWordB_toolTip":"B1, B2: intermedio, intermedio-alto",
"englishLevelWordC_toolTip":"C1, C2: avanzado, competencia",
"englishLevelWord_label":"Nivel de inglés",
"englishLevelWord_toolTipSearch":"Nivel de inglés de esta palabra",
"maturityTitle_toolTip":"Significado de madurez:",
"maturity0_toolTip":"0: aún no visto",
"maturity1_toolTip":"1-9: aprendizaje",
"maturity2_toolTip":"10-14: casi dominado",
"maturity3_toolTip":"> 14: dominada",
"maturity_label":"Tu madurez para esta palabra",
"lastPracticed_label":"Última práctica",
"lastPracticedNone_label":"ninguno",
"nextPractice_toolTip":"Próxima práctica: fecha planificada de la próxima práctica de esta palabra (cuando el factor de demora llega a 1.00. Cuanto menor sea el vencimiento, más cercana será la fecha)\nFactor de demora actual: el factor de demora entre la fecha de la última práctica y la siguiente fecha prevista de práctica",
"nextPractice_label":"Próxima práctica",
"currentFactorDelay_label":"Factor de retardo actual",
"nextPracticeIfOk_label":"Siguiente práctica si 'Ok'",
"nextPracticeIfKo_label":"Siguiente práctica si 'No Ok'",
"showWordReference_label":"Mostrar esta palabra en el sitio WordReference",
"configure_toolTip":"Configurar la aplicación",
"configure_title":"Configurar",
"tutorialMenu":"Tutorial",
"premiumMenu":"Premium",
"configureWordReferenceLanguageTranslation_title":"Idioma de traducción en WordReference",
"configureLeftHandedView_title":"Vista para zurdos",
"configureExpertModeView_title":"Modo experto",
"configureToolTips_title":"Ayuda contextual",
"configureSetMastered_toolTip":"Configura automáticamente todas las palabras hasta el siguiente nivel de inglés incluido en 'Dominado' (seleccionar)",
"configureSetMastered_title":"Ponga todas las palabras en ‘Dominado’",
"configureThemeChoice_toolTip":"Elija el tema (COCA: valor inicial correspondiente a la base del 95% de las palabras en inglés, PhrasalVerbs: solo modo premium, 150 phrasal verbs)",
"set_Label":"Go!",
"configureSetMastered_confirm":"¡Tenga cuidado, establecerá todas las palabras en ‘Dominado’ hasta el nivel de inglés ENGLISHLEVEL! ¿Confirma esta operación (no reversible)?",
"revisionModeActivate_toolTip":"Haga clic para activar el modo de revisión",
"revisionModeDeactivate_toolTip":"Haga clic para desactivar el modo de revisión",
"revisionMode_toolTip":"Modo de revisión: revisa las palabras que tienes más dificultades para memorizar",
"searchPage_toolTip":"Ir a una palabra específica",
"searchPage_Title":"Palabras de búsqueda",
"searchPageFilter_toolTip":"filtrar",
"searchPageCloseFilter_toolTip":"cerrar filtro",
"searchPageSortHistorical_label":"Práctica histórica",
"searchPageSortAlphabetical_label":"Alfabética",
"searchPageSortPracticeLevel_label":"nivel de practica",
"searchPageSortEnglishLevel_label":"Nivel de inglés",
"searchPageSortRank_label":"Rango",
"maturityLevel_label":"Nivel de madurez",
"search_label":"Buscar",
"sortOrder_label":"Orden de clasificación",
"word_label":"Palabra",
"position_label":"Posición",
"englishLevel_label":"Nivel de inglés",
"practiceMaturity_label":"Madurez de la práctica",
"lastTimePracticed_label":"Practicada la última vez",
"noResultFound_label":"No se han encontrado resultados",
"sortValue_label":"Ordenar valor",
"statistics_label":"Estadísticas",
"statisticsHistoricTotal_toolTips":"Número de palabras practicadas en el día (si la misma palabra se practica varias veces, se cuenta como tantas)",
"statisticsHistoricUnique_toolTips":"Número de palabras distintas practicadas en el día (si la misma palabra se practica varias veces, se cuenta solo una vez)",
"learningStatus_label":"Estado de aprendizaje",
"currentEnglishLevel_label":"Tu nivel de inglés actual",
"wordsToMasterToNextLevel_label":"Palabras que dominar para pasar al siguiente nivel",
"totalDistinctWords_label":"Recuento total de palabras disponibles",
"wordsCountPerDay_label":"Recuento de palabras practicadas por día",
"day_label":"Día",
"total_label":"Total",
"unique_label":"Única",
"new_label":"Nueva",
"newAlmostMastered_label":"Nueva casi dominada",
"newMastered_label":"Nuevo masterizado",
"configureAppLanguage_title":"Idioma de la aplicación",
"confirmation_label":"Confirmación",
"confirmationDialogBox_text":"Tenga cuidado, no está al comienzo de su flujo de práctica (sino en la página PAGENUMBER). ¿Quieres confirmar la acción de todos modos?",
"yes_label":"sí",
"no_label":"No",
"welcome_head":"Bienvenido al 95% de las palabras en inglés",
"welcome_head2":"Aprenda vocabulario en inglés de la manera más eficiente posible con esta aplicación",
"welcome_bullet1":"Aprende y domina las 5.000 palabras que representan el 95% de las palabras en inglés utilizadas de forma oral y alcanza el nivel C1 (experimentado). Aprendizaje progresivo basado en la frecuencia del uso oral de palabras.",
"welcome_bullet2":"Memoriza de forma óptima gracias a la repetición espaciada.",
"welcome_sentence":"El siguiente tutorial describe todas las funciones y consejos para empezar con esta aplicación de la forma más rápida y eficiente posible.\nPero antes de entrar en detalles, algunas palabras sobre los niveles de inglés a los que se refiere la aplicación, así como sobre cómo los 5.000 Se eligieron palabras de vocabulario.",
"welcomeEnglishLevel_head":"Niveles de ingles",
"welcomeEnglishLevel_introduction":"El CEFR (Common European Framework of Reference) ha definido la siguiente escala de competencias lingüísticas:",
"welcomeEnglishLevel_A0Label":"A0: gran principiante",
"welcomeEnglishLevel_A1Label":"A1: principiante (las primeras 1000 palabras más frecuentes)",
"welcomeEnglishLevel_A2Label":"A2: elemental (1600 primeras palabras más frecuentes)",
"welcomeEnglishLevel_B1Label":"B1: intermedia (2500 primeras palabras más frecuentes)",
"welcomeEnglishLevel_B2Label":"B2: intermedio alto (3700 primeras palabras más frecuentes)",
"welcomeEnglishLevel_C1Label":"C1: avanzado (5000 primeras palabras más frecuentes)",
"welcomeEnglishLevel_C2Label":"C2: fluido / bilingüe (10,000 primeras palabras más frecuentes)",
"welcomeFrequnencyList_head":"5,000 palabras",
"welcomeFrequnencyList_body":"Estas 5.000 palabras se eligieron en función de su frecuencia de uso. Fueron identificados a partir de un corpus de mil millones de palabras de varios cientos de miles de textos de diferentes géneros (películas, series de televisión, blogs, ...) desde 1990 hasta 2019.\nEstas son las 5 000 palabras habladas más utilizadas e incluyen jerga y palabras coloquiales.",
"welcomeInterfaceHome_head":"Interfaz de inicio",
"premium_title":"Premium",
"configureTheme_title":"Tema",
"welcomeInterfaceHome_intro":"Aquí encontrarás toda la información sobre la interfaz principal de la aplicación, sección por sección.",
"welcomeInterfaceHome_WordPosition":"La palabra aprender (aquí ‘smug') en la forma dada (aquí 'adjetivo').",
"welcomeInterfaceHome_inflections":"Inflexiones: las diferentes formas declinadas de la palabra. También para aprender.",
"welcomeInterfaceHome_rank":"Rango de una palabra: entre las 5.000 palabras, esta tiene el rango de 3438. Esto significa que ocupa el puesto 3438 en las palabras de uso oral más frecuente.",
"welcomeInterfaceHome_englishLevel":"Nivel de inglés de una palabra: en relación a su rango, esta palabra tiene un nivel de inglés correspondiente a B2 (intermedio superior).",
"welcomeInterfaceHome_maturity":"Tu madurez actual en esta palabra.",
"welcomeInterfaceHome_maturity2":"La madurez aumentará cuando responda 'OK', disminuirá cuando responda 'No Ok ».",
"welcomeInterfaceHome_maturity3":"La frecuencia de la práctica de una palabra depende de su madurez en esa palabra. Cuanto mayor sea la madurez, menor será la frecuencia.",
"welcomeInterfaceHome_maturity4":"Por ejemplo, para una madurez de 2, la frecuencia de práctica será cada 2 minutos (5:16 minutos - 10: 8 horas - 15:11 días).",
"welcomeInterfaceHome_wordreference":"Haga clic en este enlace para acceder al diccionario WordReference de esa palabra y aprender o comprobar su significado y pronunciación.\nPuede configurar el idioma de traducción de WordReference en el menú 'Configuración'.",
"welcomeInterfaceHome_sidenavTitle":"Esta es la barra de navegación principal:",
"welcomeInterfaceHome_bottomnavTitle":"Esta barra de navegación inferior adicional le permite navegar a través de su flujo de prácticas.",
"welcomeInterfaceHome_bottomnavButtons1":"El flujo de la práctica es la secuencia de palabras para practicar en un momento dado. El flujo se ordena en función de la demora en la práctica de la palabra (que se basa en su madurez y la fecha de la última práctica).",
"welcomeInterfaceHome_bottomnavButtons2":"Utilice los botones para navegar. El número en el centro indica el número de secuencia actual de la palabra. Se supone que debes practicar las palabras al principio (número de secuencia = 1).",
"welcomeInterfaceHomeExpert_head":"Modo experto",
"welcomeInterfaceHomeExpert_intro":"Cuando el modo experto está habilitado, algunas funciones adicionales están disponibles:\n • En la interfaz de inicio: vea a continuación,\n • Palabras de búsqueda: vea la página siguiente.",
"welcomeInterfaceHomeExpert_intro2":"Arriba, la interfaz principal cuando está activado el modo experto.",
"welcomeInterfaceHomeExpert_maturity":"Hay nueva información adicional disponible en modo experto en el área de madurez:",
"welcomeWordReference_head":"Interfaz WordReference",
"welcomeWordReference_intro":"Usa el diccionario para repasar una palabra o para aprenderla.\nEs importante aprender la pronunciación (haz clic en el botón 'Escuchar' y repítelo en voz alta hasta que sea exactamente la misma pronunciación), los diferentes significados de la palabra para su posición ( aquí 'petulante' como 'adjetivo') y ver su uso en situaciones (a través de ejemplos dados).",
"welcomeConfiguration_head":"Configuración",
"welcomeConfiguration_intro":"Esta página le permite configurar la aplicación:",
"configurationLanguage_toolTips":"Idioma de la aplicación: elija el idioma de la interfaz de la aplicación",
"configurationWR_toolTips":"Idioma de traducción de WordReference: elija su idioma de traducción preferido para su diccionario",
"configurationLeft_toolTips":"Vista para zurdos: seleccione esta opción si prefiere tener los botones de práctica a la izquierda de la pantalla",
"configurationExpert_toolTips":"Vista del modo experto: seleccione esta opción si desea acceder a funciones avanzadas (busque una palabra, navegue por su flujo de práctica actual, tenga información adicional en la pantalla de inicio, obtenga acceso al modo de revisión)",
"configurationTips_toolTips":"Consejos sobre herramientas: si está habilitado, una pulsación larga en un área en alguna pantalla mostrará algunas guías relacionadas con el área seleccionada",
"configurationThemeChoice_toolTips":"Elección del tema (solo versión premium): le permite seleccionar el tema de las palabras para practicar.\nValores posibles: COCA (valor inicial correspondiente a la base del 95% de las palabras en inglés), PhrasalVerbs (solo versión premium, 150 phrasal verbs)",
"welcomeSearch_head":"Buscar una palabra",
"welcomeSearch_intro":"Esta página le permite buscar palabras. Puede escribir una palabra, filtrar por criterios de nivel de madurez o de inglés y ordenar el resultado según diferentes criterios,\nHaga clic en una palabra para mostrarla en su pantalla de inicio.",
"welcomeStats_head":"Estadísticas",
"welcomeStats_intro":"Esta página muestra algunas estadísticas relacionadas con su progresión y sus prácticas diarias.",
"welcomeTips_head":"Consejos",
"welcomeTips_intro1":"Navegación: Para volver (desde el diccionario, configuración, estadísticas, búsqueda), limpie hacia la derecha desde el borde izquierdo de la pantalla.\nArriba, por ejemplo, para volver a la pantalla de inicio desde el diccionario.",
"welcomeTips_intro2":"Información sobre herramientas: una pulsación larga en una sección mostrará algunas guías. Arriba, la información que se muestra después de una pulsación larga en el área 'Madurez'.",
"welcomePremium_head":"Versión Premium",
"welcomePremium_intro":"La versión Premium ofrece el siguiente contenido adicional",
"welcomePremium_bullet1":"Acceso a las 5.000 palabras (5.050 en realidad) que cubren el 95% de las palabras en inglés utilizadas oralmente",
"welcomePremium_bullet2":"Acceso al nuevo tema PhrasalVerbs que contiene los 150 phrasal verbs en inglés más utilizados (60% del total). Los niveles de inglés son específicos sobre este tema (los 150 verbos corresponden al nivel B2)",
"welcomeLast_head":"Al principio…",
"welcomeLast_sentence":"Al principio, dependiendo de tu nivel de inglés, ya sabrás un mayor o menor número de palabras. Luego, toque Dominado para cada palabra que ya domine.\nTen paciencia, al principio es posible que deba realizar esta operación para varios cientos de palabras. Este es un paso necesario para asegurarse de que no se pierda algunas palabras básicas que no dominará. Una vez que termine este primer paso, encontrará cada vez más palabras que aún no domina.\n¡Disfrútelo!",
"welcomeLast_but":"Maíz…",
"welcomeLast_setmastered":"Bien, si está un poco impaciente y realmente seguro de que ya domina uno o más niveles, es posible ‘Dominar’ automáticamente todas las palabras en uno o más niveles. Para hacer esto, vaya al menú de configuración y se mostrará la línea de arriba. Elija el nivel hasta el que poner todas las palabras en ‘Dominado’ y presione el botón.",
"welcomeLast_sentence2":"Un último consejo:\nNo dudes en seleccionar 'No Ok' tan pronto como no te sientas completamente cómodo con una palabra (en su significado o pronunciación), ¡incluso si has alcanzado un alto nivel de madurez, por el contrario!\nIt Es bastante normal (y muy recomendable) tener palabras regulares para volver a practicar con más frecuencia, y esto puede suceder varias veces con la misma palabra.\nDespués de un tiempo de práctica, active ocasionalmente el modo de revisión (imagen debajo) para revisar todas las palabras que tuvo más dificultades para practicar.",
"welcomeLast_sentence3":"Cuando el modo de revisión está habilitado, la interfaz evoluciona ligeramente:",
"welcomeLast_sentence3_bullet1":"aún puede usar los botones ‘No Ok’ y ‘Ok', pero solo tendrán un efecto significativo en la madurez si el factor de retraso actual es superior a 1,00. Por otro lado, la palabra ya no se le ofrecerá en el modo de revisión durante 1 hora,",
"welcomeLast_sentence3_bullet2":"El botón 'masterizado' se sustituye por el botón 'Detener revisión de esta palabra'. Al hacer clic en este botón, el contador de revisiones se restablecerá a cero para esta palabra. Entonces ya no se le ofrecerá para su revisión a menos que aún tenga dificultades para retenerlo más tarde ('No está bien' seleccionado varias veces después).",
"adminMenu":"Admin",
"premium_subscribe":"Comprar",
"premium_restore":"Restaurar las compras",
"premium_availableproducts":"Productos disponibles",
"premium_freemium":"Gratis",
"premium_freemiumdescription":"Acceso a las primeras 2500 palabras (nivel B1)",
"premium_restoreNotFound":"No se encontró ninguna compra anterior",
"reviewapp_title":"Califica la aplicación",
"expertModePremiumOnly_title":"Modo experto accesible en la versión Premium",
"expertModePremiumOnly_dialog":"Para acceder al modo experto, suscríbase a la versión Premium",
"themeChoicePremiumOnly_title":"La elección del tema solo es posible en la versión Premium",
"themeChoicePremiumOnly_dialog":"Para poder cambiar el tema, suscríbete a la versión Premium",
"countWordsToPractice_title":"No de palabras con ‘factor de demora’ actual> 1",
"underConstruction":"En construcción...",
"privacyPolicy_label":"Política de privacidad",
"inshort_label":"En breve",
"inshort_sentence":"95% English Words no recopila ni comparte su información personal.\n\nNo lo rastreamos. No te perfilamos. ¡Es todo!",
"presentation_title":"Presentación de la aplicación",
"presentation_appAppStore":"Esta aplicación está disponible en Apple Store para iPhone o iPad",
"presentation_appAndroidStore":"Próximamente disponible en la tienda de Android.",
"appLink":"https://apps.apple.com/es/app/95-english-words/id1590141828",
"appLinkAndroid":"https://play.google.com/store/apps/details?id=com.lezoomer.englishwords",
"appleApp_ID":"1590141828",
"appDownload_title":"Descarga en la App Store",
"appKeywords":"Inglés, vocabulario, hablado, idioma, jerga, frecuencia, bilingüe, aprender, lista de frecuencias, vocabulario en inglés, repetición espaciada, flashcards",
"appDescription":"Accesible para todos, bastan unos minutos al día en nuestra aplicación móvil. Aprende las 5000 palabras en inglés que componen el 95% del vocabulario hablado.",
"app_h1_1":"¡El método efectivo y relevante para aprender palabras comunes en inglés!",
"app_h2_1":"Aprende con esta aplicación el vocabulario en inglés equivalente al nivel internacional C1 experimentado",
"app_h3_1":"Un vocabulario pertinente",
"app_h3_1_description":"Domina las 5000 palabras que representan el 95% de las palabras en inglés usadas oralmente. El aprendizaje es progresivo y se basa en la frecuencia del uso de palabras orales.",
"app_h3_2":"Un método efectivo",
"app_h3_2_description":"Memoriza de forma óptima gracias a la repetición espaciada, la técnica para retenerlo todo con el mínimo esfuerzo.",
"app_h3_3":"Un diccionario de referencia mundial",
"app_h3_3_description":"Aprende las palabras, sus pronunciaciones, sus definiciones y ejemplos de uso con el apoyo del excelente y famoso diccionario colaborativo WordReference.",
"app_h3_4":"Practica cuando quieras, donde quieras",
"app_h3_4_description":"5 minutos de descanso? Aproveche la oportunidad de practicar con nuestra aplicación para iPhone y iPad (y pronto para Android).\nLas primeras 2500 palabras son gratuitas con acceso a todas las funciones de la aplicación.\nLa versión de pago da acceso a las 5000 palabras, así como a las 150 frases más frecuentes.",
"app_h2_2":"Una interfaz simple, rica y efectiva.",
"app_h2_3":"Descarga nuestra aplicación para iPhone o iPad",
"wordfrequency_link":"https://www.wordfrequency.info",
"wordreference_link":"https://www.wordreference.com",
"spacedrepetition_link":"https://es.wikipedia.org/wiki/Repaso_espaciado",
"spacedrepetition_imgpath":"/img/repetitionsespacees.png"};

