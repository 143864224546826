import React from 'react';
import "./css/HeaderEW.css";
import logo from "./img/Icon-95-percente-english-words.png"
import "./css/EnglishWords.css"

export default function HeaderEW({ Title }) {
  return (
    <div className='headerContainer'>
      <div className="headerEW">
        <div className="body_column">
          <div className='logoEW'>

            <a href="/englishwords">
              <img alt="95% English Words - home page" src={logo} height="50" />
            </a>
          </div>
          <div className="headerEWText">{Title}
          </div>
        </div>
      </div>
    </div>
  );
}