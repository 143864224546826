import { React } from 'react';
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import { Helmet } from "react-helmet";

export default function MetaGeneric() {
    const { t } = useTranslation();

    return (
        <Helmet>
            <meta name="apple-mobile-web-app-title" content={t("appTitle")} />
            <meta name="apple-itunes-app" content={"app-id=" + t('appleApp_ID')} />
            <meta name="keywords" content={t("appKeywords")} />
            <meta name="og:description" content={t("appDescription")} />
            <meta name="description" content={t("appDescription")} />
        </Helmet>
    );
}