import { React, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import HeaderEW from "./HeaderEW";
import "./css/privacyEW.css"


export default function Privacy() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "95% English Words - Privacy policies"
  }, []);

  return (
    <div className="englishwords">
      <HeaderEW
        Title={t("privacyPolicy_label")}
      />
      <div className='body_row'>
        <div className="body_column">
          <div className='body_content_noline'>
            <div className="privacyEW">
              <div className="privacyEWContent">
                <div className="inshort">
                  {t("inshort_label")}
                </div>
                <div className="line" />
                <div className="sentence">
                  {t("inshort_sentence")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
