import React from 'react';
import {
  BrowserRouter as Router, 
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import EnglishWords from './EnglishWords'
import Lezoomer from './Lezoomer'
import Privacy from './Privacy'


export default function MyRoutes() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to="/englishwords" />} />
          <Route exact path="/englishwords/privacy" element={<Privacy />} />
          <Route exact path="/englishwords" element={<EnglishWords />} />
        </Routes>
      </Router>
    );
  }