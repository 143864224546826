import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN } from "./translations.js";
import { TRANSLATIONS_FR } from "./translations.js";
import { TRANSLATIONS_ES } from "./translations.js";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     fr: {
       translation: TRANSLATIONS_FR
     },
     es: {
      translation: TRANSLATIONS_ES
    }

   },
   fallbackLng: "en",
   detection: { order: ["navigator"] },
 });
 
i18n.changeLanguage();