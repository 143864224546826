import { React } from 'react';
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import HeaderEW from "./HeaderEW";
import "./css/EnglishWords.css"
import { Helmet } from "react-helmet";
import MetaGeneric from "./metaGeneric";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const itemHeight = "400px";
const items = [
  <img src="/img/Guide/en/Tutorial.png" onDragStart={handleDragStart} alt="presentation 3" height={itemHeight} />,
  <img src="/img/Guide/en/InterfaceHome_Expert.png" onDragStart={handleDragStart} alt="presentation 1" height={itemHeight} />,
  <img src="/img/Guide/en/WordReferenceHome.png" onDragStart={handleDragStart} alt="presentation 3" height={itemHeight} />,
  <img src="/img/Guide/en/Configuration.png" onDragStart={handleDragStart} alt="presentation 2" height={itemHeight} />,
  <img src="/img/Guide/en/Search1.png" onDragStart={handleDragStart} alt="presentation 3" height={itemHeight} />,
  <img src="/img/Guide/en/Search2.png" onDragStart={handleDragStart} alt="presentation 3" height={itemHeight} />,
  <img src="/img/Guide/en/Stats1.png" onDragStart={handleDragStart} alt="presentation 3" height={itemHeight} />,
];

const responsive = {
  0: { items: 1 },
  600: { items: 2 },
  1065: { items: 4 },
};

const imgWordFrequency = "/img/words-frequency-logo.jpeg";
const imgSpacedRepetition = "/img/repetitionsespacees.png";
const imgWordReference = "/img/WR_fbicon.jpg";
const img95EW = "/img/SplashScreen.png";
const logoWidth = "100px";

export default function EnglishWords() {
  const { t } = useTranslation();

  return (
    <div className="englishwords">
      <MetaGeneric />
      <Helmet>
        <title>{t("appTitleLong")}</title>
      </Helmet>
      <HeaderEW
        Title=""
      />
      <div className='body_row plain_row'>
        <div className="body_column">
          <div className='body_content'>
            <div className="titleEW">
              {t("welcome_head")}<br /></div>
            <h1>{t("app_h1_1")}</h1>

          </div>
        </div>
      </div>
      <div className='body_row'>
        <div className="body_column">
          <div className='body_content_noline'>
            <div className="title2EW">
              <h2>{t("app_h2_1")}</h2>
            </div>
            <div className='flex_div'>
              <div className='flex_divColumn'>
                <div className='h21_bloc'>
                  <div className='h21_bloc_logo'>
                    <a href={t("wordfrequency_link")} target="_blank" rel="noreferrer" alt="word frequency">
                      <img src={imgWordFrequency} onDragStart={handleDragStart} alt="word frequency" width={logoWidth} />
                    </a>
                  </div>
                  <div className='h21_bloc_content'>
                    <div className="title3EW">
                      <h3>{t("app_h3_1")}</h3>
                    </div>
                    <div>
                      {t("app_h3_1_description")}
                    </div>
                  </div>
                </div>
                <div className='h21_bloc'>
                  <div className='h21_bloc_logo'>
                    <a href={t("spacedrepetition_link")} target="_blank" rel="noreferrer" alt="spaced repetition">
                      <img src={t("spacedrepetition_imgpath")} onDragStart={handleDragStart} alt="spaced repetition" width={logoWidth} />
                    </a>
                  </div>
                  <div className='h21_bloc_content'>
                    <div className="title3EW">
                      <h3>{t("app_h3_2")}</h3>
                    </div>
                    <div>
                      {t("app_h3_2_description")}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex_divColumn'>
                <div className='h21_bloc'>
                  <div className='h21_bloc_logo'>
                    <a href={t("wordreference_link")} target="_blank" rel="noreferrer" alt="word reference">
                      <img src={imgWordReference} onDragStart={handleDragStart} alt="word reference" width={logoWidth} />
                    </a>
                  </div>
                  <div className='h21_bloc_content'>
                    <div className="title3EW">
                      <h3>{t("app_h3_3")}</h3>
                    </div>
                    <div>
                      {t("app_h3_3_description")}
                    </div>
                  </div>
                </div>
                <div className='h21_bloc'>
                  <div className='h21_bloc_logo'>
                    <a href={t("appLink")} target="_blank" rel="noreferrer" alt="app store link">
                      <img src={img95EW} onDragStart={handleDragStart} alt="app store link" width={logoWidth} />
                    </a>
                  </div>
                  <div className='h21_bloc_content'>
                    <div className="title3EW">
                      <h3>{t("app_h3_4")}</h3>
                    </div>
                    <div>
                      {t("app_h3_4_description")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='body_row'>
        <div className="body_column">
          <div className='body_content'>
            <div className="title2EW">
              <h2>{t("app_h2_3")}</h2>
            </div>
            <div className='alicecarousel'>
              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls="true"
              />
            </div>
            <AppStoreDownload />
            <br />
          </div>
        </div>
      </div>
    </div >
  );
}

//           <path xmlns="http://www.w3.org/2000/svg" d="m 133.6,126.251 c -2.45,5.66 -5.35,10.87 -8.71,15.66 -4.58,6.53 -8.33,11.05 -11.22,13.56 -4.48,4.12 -9.28,6.23 -14.42,6.35 -3.69,0 -8.14,-1.05 -13.32,-3.18 -5.197,-2.12 -9.973,-3.17 -14.34,-3.17 -4.58,0 -9.492,1.05 -14.746,3.17 -5.262,2.13 -9.501,3.24 -12.742,3.35 -4.929,0.21 -9.842,-1.96 -14.746,-6.52 -3.13,-2.73 -7.045,-7.41 -11.735,-14.04 -5.032,-7.08 -9.169,-15.29 -12.41,-24.65 C 1.74,106.671 0,96.881 0,87.403 0,76.546 2.346,67.182 7.045,59.335 10.738,53.032 15.651,48.06 21.8,44.41 c 6.149,-3.65 12.793,-5.51 19.948,-5.629 3.915,0 9.049,1.211 15.429,3.591 6.362,2.388 10.447,3.599 12.238,3.599 1.339,0 5.877,-1.416 13.57,-4.239 7.275,-2.618 13.415,-3.702 18.445,-3.275 13.63,1.1 23.87,6.473 30.68,16.153 -12.19,7.386 -18.22,17.731 -18.1,31.002 0.11,10.337 3.86,18.939 11.23,25.769 3.34,3.17 7.07,5.62 11.22,7.36 -0.9,2.61 -1.85,5.11 -2.86,7.51 z M 102.34,3.241 c 0,8.1021 -2.96,15.667 -8.86,22.669 -7.12,8.324 -15.732,13.134 -25.071,12.375 -0.119,-0.972 -0.188,-1.995 -0.188,-3.07 0,-7.778 3.386,-16.102 9.399,-22.908 3.002,-3.446 6.82,-6.3113 11.45,-8.597 4.62,-2.2516 8.99,-3.4968 13.1,-3.71 0.12,1.0831 0.17,2.1663 0.17,3.2409 z" id="path4" style="fill:url(#linearGradient3761);fill-opacity:1"/>

function AppStoreDownload() {
  const { t } = useTranslation();

  return (
    <div className='appsdownload'>
      <div className='appdownload'>
        <a href={t("appLink")} target="_blank" rel="noreferrer" alt="Download app on apple store">
          <div className='appdownload_content'>
            <div className='appdownload_icon'>
              <svg width="20" viewBox="0 0 140 165">
                <path
                  className="polymorph"
                  d="m 133.6,126.251 c -2.45,5.66 -5.35,10.87 -8.71,15.66 -4.58,6.53 -8.33,11.05 -11.22,13.56 -4.48,4.12 -9.28,6.23 -14.42,6.35 -3.69,0 -8.14,-1.05 -13.32,-3.18 -5.197,-2.12 -9.973,-3.17 -14.34,-3.17 -4.58,0 -9.492,1.05 -14.746,3.17 -5.262,2.13 -9.501,3.24 -12.742,3.35 -4.929,0.21 -9.842,-1.96 -14.746,-6.52 -3.13,-2.73 -7.045,-7.41 -11.735,-14.04 -5.032,-7.08 -9.169,-15.29 -12.41,-24.65 C 1.74,106.671 0,96.881 0,87.403 0,76.546 2.346,67.182 7.045,59.335 10.738,53.032 15.651,48.06 21.8,44.41 c 6.149,-3.65 12.793,-5.51 19.948,-5.629 3.915,0 9.049,1.211 15.429,3.591 6.362,2.388 10.447,3.599 12.238,3.599 1.339,0 5.877,-1.416 13.57,-4.239 7.275,-2.618 13.415,-3.702 18.445,-3.275 13.63,1.1 23.87,6.473 30.68,16.153 -12.19,7.386 -18.22,17.731 -18.1,31.002 0.11,10.337 3.86,18.939 11.23,25.769 3.34,3.17 7.07,5.62 11.22,7.36 -0.9,2.61 -1.85,5.11 -2.86,7.51 z M 102.34,3.241 c 0,8.1021 -2.96,15.667 -8.86,22.669 -7.12,8.324 -15.732,13.134 -25.071,12.375 -0.119,-0.972 -0.188,-1.995 -0.188,-3.07 0,-7.778 3.386,-16.102 9.399,-22.908 3.002,-3.446 6.82,-6.3113 11.45,-8.597 4.62,-2.2516 8.99,-3.4968 13.1,-3.71 0.12,1.0831 0.17,2.1663 0.17,3.2409 z"
                />
              </svg>
            </div>
            <div className='appdownload_text'>
              {t("appDownload_title")}
            </div>
          </div>
        </a >
      </div >
      <div className='appdownload'>
        <a href={t("appLinkAndroid")} target="_blank" rel="noreferrer" alt="Download app on apple store">
          <div className='appdownload_content'>
            <div className='appdownload_icon'>
              <svg width="20" viewBox="0 0 512 512">
                <path
                  d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z" /><path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" /><path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" /><path d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z"
                />
              </svg>
            </div>
            <div className='appdownload_text'>
              {t("presentation_appAndroidStore")}
            </div>
          </div>
        </a >
      </div >
    </div>
  );
}